import { navigate } from 'gatsby'
import React from 'react'
import { useSelector } from 'react-redux'
import {RootState} from 'reducers'
import Sidebar from './Sidebar'
import {on} from 'jetemit'
import Header from './Header'
import 'scss/main.scss'

type Props = {
    children: JSX.Element | JSX.Element[]
}
const AdminLayout = (props: Props) => {
    const user = useSelector((state: RootState) => state.user)
    console.log('>>: user > ', user)
    React.useEffect(() => {
        on('get-user', () => {
            return user
        })
        if(!user)
            navigate('/login')
    }, [user])
    return (
        <div className="layout">
            <Header />
            <div className="row">
                <div className="col-md-3 col-sm-0">
                    <Sidebar />
                </div>
                <div className="col-md-9 col-sm-12">
                    {
                        props.children
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminLayout