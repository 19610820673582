import React from 'react'
import { Provider } from 'models'
import {Input, Button, Textarea} from 'components'
import { quitLoading, setLoading, showError, showSuccess } from 'utils'
import { ProviderService } from 'services/modules/ProviderService'
type Props = {
    element: Provider | null,
    onClose: () => void
}
const CreateEditProvider = (props: Props) => {
    const IProviderState: Provider = {
        name: '',
        description: '',
        phone: '',
        email: '',
    }
    const [provider, setProvider] = React.useState<Provider>(IProviderState)
    React.useEffect(() => {
        if(props.element){
            setProvider({
                ... props.element
            })
        }
    }, [])
    const change = (key: keyof Provider, value: string) => {
        const _provider: Provider = {
            ... provider,
            [key]: value
        }
        setProvider(_provider);
    };
    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading()
        try {
            const {msg} = await ProviderService[props.element ? 'update' : 'create'](provider)
            showSuccess(msg)
            props.onClose()
        } catch (error) {
            showError(error?.response?.data?.msg)
        }finally{
            quitLoading()
        }
    }
    return(
        <div className="container">
            <div className="w-100 text-center">
                <h4 className="text-dark">
                    Crear / Editar Proveedor
                </h4>
            </div>
                <form onSubmit={submit}>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Input
                                name="name"
                                value={provider.name}
                                onChange={(value: string) => change('name', value)}
                                label="Nombre"
                                required
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Input
                                name="phone"
                                value={provider.phone}
                                onChange={(value: string) => change('phone', value)}
                                label="Teléfono"
                                required
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Input
                                name="email"
                                value={provider.email}
                                type="email"
                                onChange={(value: string) => change('email', value)}
                                label="Email"
                                required
                            />
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Textarea
                                name="description"
                                label="Descripcion"
                                value={provider.description || ''}
                                onChange={(value: string) => change('description', value)}
                            />
                        </div>
                    </div>
                    <div className="w-100 text-center">
                        <Button
                            label="Guardar"
                            type="submit"
                        />
                    </div>
                </form>
            
        </div>
    )
}

export default CreateEditProvider