import React from 'react'
import { Plan, Coverage } from 'models'
import {formatMiles} from 'utils'

type Props = {
    plan: Plan | null
}
export default (props: Props) => {
    return(
        <div className="container">
            <div className="w-100 text-center">
                <img
                    src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+props.plan?.insurance?.logo}
                    alt={props.plan?.insurance?.name}
                />
            </div>
            <React.Fragment>
                {
                    props.plan?.items?.map((element: Coverage, i: number) => {
                        return(
                            <div
                                className="row"
                                key={i}
                            >
                                <div className="col-8 ellipsis">
                                    <p className="text-dark-gray">
                                        {
                                            element.name
                                        }
                                    </p>
                                </div>
                                <div className="col-4 ellipsis">
                                    <p className="text-dark-gray">
                                        {
                                            parseFloat(element.coverage) && formatMiles(parseFloat(element.coverage), false) || element.coverage
                                        }
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </React.Fragment>
        </div>
    )
}