import React from 'react'
import { Icon } from 'components'
import { navigate } from 'gatsby'

const Sidebar = () => {
    const items = [
        {
            label: 'Proveedores',
            icon: 'user',
            value: '/providers'
        },
        {
            label: 'Planes y coberturas',
            icon: 'medkit',
            value: '/plans-and-coverage'
        },
        {
            label: 'Coberturas comunes',
            icon: 'address-book',
            value: '/comun-coverages'
        },
        {
            label: 'Cotizaciones',
            icon: 'address-book',
            value: '/invoices'
        },
    ]
    return(
        <div
            className="sidebar"
            style={{
                height: '100%',
                minHeight: '100vh'
            }}
        >
            {
                items?.map((element, i) => {
                    return(
                        <div
                            className="w-100 text-white cursor-pointer text-center"
                            key={i}
                            onClick={() => navigate(element.value)}
                        >
                            <p
                                className="ellipsis"
                            >
                                <Icon
                                    name={element.icon+' p-1'}
                                />
                                {element.label}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Sidebar