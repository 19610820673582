import React from 'react'
import { PlanPrevItems as Coverage, Provider, Plan} from 'models'
import { quitLoading, setLoading, showError } from 'utils'
import { ComunCoverageService } from 'services'
import { Input } from 'components'

type Props = {
    coverages: Coverage[],
    onClose: () => void
}
type PlanCustomRender = {
    planId: number;
    insuranceId: string | number;
    value: string | number;
}
type CustomRenderItem = {
    coverage: Coverage,
    // provider: Provider,
    plans: PlanCustomRender[]
}
export default (props: Props) => {
    const [providers, setProviders] = React.useState<string[]>([])
    const [plans, setPlans] = React.useState<string[]>([])
    const [providersWithPlans, setProvidersWithPlans] = React.useState<CustomRenderItem[]>([])
    const load = async () => {
        try {
            setLoading()
            const res = await ComunCoverageService.getProvidersWithPlans()
            const _providers = res.insurances.map(element => element.name)
            const _plans = res.plans.map(element => element.name +' ('+element.insurance?.name || ''+')')
            setPlans(_plans)
            setProviders(_providers)
            let data: CustomRenderItem[] = []
            for (let index = 0; index < props.coverages.length; index++) {
                const coverage = props.coverages[index]
                const plans: PlanCustomRender[] = res.plans.map(element => {
                    return {
                        planId: element.id,
                        insuranceId: element.id_insurance,
                        value: element.comun_coverages?.find(_element => _element.prev_item_id === coverage.id)?.value || ''
                    }
                })
                data.push({
                    coverage,
                    plans
                })
            }
            setProvidersWithPlans(data)
        } catch (error) {
            console.log('>>: error > ', error)
            showError()
        }finally{
            quitLoading()
        }
    }
    React.useEffect(() => {
        load()
    }, [])
    const changeCoverage = (value: string | number, element: PlanCustomRender, i: number, _i: number) => {
        const _providers = [... providersWithPlans]
        if(_providers[_i]?.plans?.[i]){
            _providers[_i].plans[i].value = value
            setProvidersWithPlans(_providers)
        }
    }
    const save = async () => {
        try {
            setLoading()
            const res = await ComunCoverageService.save(providersWithPlans)
        } catch (error) {
            showError()
        }finally{
            quitLoading()
        }
    }
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Cobertura</th>
                    {
                        plans.map(element => {
                            return(
                                <th scope="col" style={{minWidth: '10rem'}}  key={element}>{element}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    providersWithPlans.map((element, _i) => {
                        return(
                            <tr
                                key={element.coverage?.name || ''}
                            >
                                <th scope="row">
                                    {element.coverage.name}
                                </th>
                                {
                                    element.plans.map((_element, i) => {
                                        return(
                                            <td
                                                key={i}
                                            >
                                                <Input
                                                    onChange={(value: string | number) => changeCoverage(value, _element, i, _i)}
                                                    value={_element.value}
                                                    name="value"
                                                />
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
