import React from 'react'
import { Plan, Provider, Coverage, PlanPrevItems, PlanPrice } from 'models'
import { quitLoading, setLoading, showError, showSuccess } from 'utils'
import { PlanService, ProviderService } from 'services'
import {Select, Input, Button} from 'components'
import {Tabs, Tab} from 'react-bootstrap'
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules

type Props = {
    element: Plan | null,
    onClose: () => void
}
type Form = {
    provider_id: string | number,
    name: string,
    id?: number,
    northAmerica: 'y' | 'n' | null,
    southAmerica: 'y' | 'n' | null,
    europe: 'y' | 'n' | null,
    world: 'y' | 'n' | null,
    centralAmerica: 'y' | 'n' | null,
    nacional: 'y' | 'n' | null,
    age_limit: number,
    day_limit_condition: string,
    day_limit: string,
    items: Coverage[],
}
type PrevItem = PlanPrevItems & {value?: string}
const CreateEditPlans = (props: Props) => {
    const IFormState: Form = {
        provider_id: '',
        day_limit_condition: '==',
        day_limit: '',
        age_limit: 125,
        name: '',
        northAmerica: 'n',
        southAmerica: 'n',
        europe: 'n',
        world: 'n',
        items: [],
        centralAmerica: 'n',
        nacional: 'n',
    }
    const [providers, setProviders] = React.useState<Provider[]>([])
    const [form, setForm] = React.useState<Form>(IFormState)
    const [planPrevItems, setPlanPrevItems] = React.useState<PrevItem[]>([])
    const [travelDays, setTravelDays] = React.useState<number>(365)
    const [dayPrice, setDayPrice] = React.useState<number>(1)
    const [prices, setPrices] = React.useState<number[]>([])
    const [key, setKey] = React.useState<string>('home');
    const changeComunCoverage = (i: number, value: string) => {
        let _prevItems = [... planPrevItems]
        _prevItems[i].value = value
        setPlanPrevItems(_prevItems)
    }
    const calcPriceDays = (_travelDays: number, _dayPrice: number, isFirstLoad = false) => {
        const _prices = Array.from(Array(_travelDays)).map((element, i) => {
            let value = parseFloat((_dayPrice * (i+1)).toFixed(2))
            if(isFirstLoad && props.element?.prices?.[i]?.type === 'day'){
                value = parseFloat(props.element?.prices?.[i].price_1)
            }
            return value
        })
        setPrices(_prices)
    }
    const changeDayPrice = (value: number, i: number) => {
        let _prices = [... prices]
        _prices[i] = value
        setPrices(_prices)
    }
    const load = async () => {
        try {
            setLoading()
            const _providers = await ProviderService.get()
            const _prevItems = await PlanService.getPrevItems()
            const items = _prevItems.map((element: PlanPrevItems): PrevItem  => {
                const comunCoverage = props.element?.comun_coverages?.find(_element => _element.prev_item_id === element.id)
                return {
                    ... element,
                    value: comunCoverage?.value || ''
                }
            })
            if(props.element?.price){
                setDayPrice(props.element.price)
            }
            setPlanPrevItems(items)
            setProviders(_providers)
        } catch (error) {
            showError()
            props.onClose()
        }finally{
            quitLoading()
            calcPriceDays(travelDays, dayPrice, true)
        }
    }
    React.useEffect(() => {
        load()
        if(props.element)
            setForm({
                ... form,
                ... props.element,
                provider_id: props.element.id_insurance
            })
    }, [])
    const change = (key: keyof Form, value: string | number) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form)
    }
    const changeToggle = (key: keyof Form, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked ? 'y' : 'n'
        const _form = {
            ... form,
            [key]: value
        }
        setForm(_form)
    }
    const changeCoverage = (key: keyof Coverage, value: string, index: number) => {
        let items = [... form.items]
        //@ts-ignore
        items[index][key] = value
        setForm({
            ... form,
            items
        })
    }
    const deleteCoverage = (index: number) => {
        let items = [... form.items]
        items.splice(index, 1)
        setForm({
            ... form,
            items
        })
    }
    const addCoverage = () => {
        let items = [... form.items]
        items.push({
            coverage: '',
            data_0: '',
            data_1: '',
            data_2: '',
            description: '',
            id: 0,
            id_insurance: '',
            id_insurance_plan: '',
            name: '',
            slug: '',
        })
        setForm({
            ... form,
            items
        })
    }
    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            setLoading()
            const _form = {
                ... form,
                dayPrice,
                prices,
                travelDays,
                planPrevItems
            }
            const {msg} = await PlanService[props.element ? 'update' : 'save'](_form)
            showSuccess(msg)
            props.onClose()
        } catch (error) {
            showError(error?.response?.data?.msg)
        }finally{
            quitLoading()
        }
    }
    return(
        <div className="container">
            <form onSubmit={submit}>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Select
                            name="provider_id"
                            options={providers.map(element => {
                                return {
                                    label: element.name,
                                    value: element.id || ''
                                }
                            })}
                            label="Proveedor"
                            value={form.provider_id}
                            onChange={(value: string) => change('provider_id', value)}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Input
                            name="name"
                            onChange={(value: string) => change('name', value)}
                            value={form.name}
                            label="Nombre del plan"
                            required
                        />
                    </div>
                    <div className="col-md-2 col-sm-4">
                        <label>Norte América</label>
                        <br/>
                        <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeToggle('northAmerica', e)}
                            checked={form.northAmerica === 'y'}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4">
                        <label>Sur América</label>
                        <br/>
                        <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeToggle('southAmerica', e)}
                            checked={form.southAmerica === 'y'}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4">
                        <label>Europa</label>
                        <br/>
                        <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeToggle('europe', e)}
                            checked={form.europe === 'y'}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4">
                        <label>Mundo</label>
                        <br/>
                        <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeToggle('world', e)}
                            checked={form.world === 'y'}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4">
                        <label>Centro américa</label>
                        <br/>
                        <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeToggle('centralAmerica', e)}
                            checked={form.centralAmerica === 'y'}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4">
                        <label>Nacional</label>
                        <br/>
                        <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeToggle('nacional', e)}
                            checked={form.nacional === 'y'}
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <Input
                            name="age_limit"
                            value={form.age_limit}
                            label="Limite de edad"
                            onChange={(value: string) => change('age_limit', parseInt(value))}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <Select
                            name="day_limit_condition"
                            options={[
                                {
                                    label: 'Igual que',
                                    value: '=='
                                },
                                {
                                    label: 'Mayor o igual',
                                    value: '>=',
                                },
                                {
                                    label: 'Menor o igual',
                                    value: '<='
                                },
                                {
                                    label: 'Intervalo',
                                    value: 'between'
                                }
                            ]}
                            label="Condicion para limite de dias de viaje"
                            value={form.day_limit_condition}
                            onChange={(value: string) => change('day_limit_condition', value)}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <Input
                            name="day_limit"
                            value={form.day_limit}
                            onChange={(value: string) => change('day_limit', value)}
                            label="Limite de dias"
                            required
                        />
                    </div>
                    <div className="container w-100">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k || 'Home')}
                            >
                            <Tab eventKey="home" title="Coberturas">
                                <div className="container">
                                    <div className="w-100 text-center p-2">
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <h4>Coberturas</h4>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-left">
                                                    <Button
                                                        icon="plus"
                                                        className="success"
                                                        small
                                                        onClick={() => addCoverage()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        form.items.map((element, i) => {
                                            return(
                                                <div
                                                    className="row"
                                                    key={i}
                                                >
                                                    <div className="col-md-5 col-sm-12">
                                                        <Input
                                                            name="name"
                                                            label="Nombre"
                                                            value={element.name}
                                                            onChange={(value: string) => changeCoverage('name', value, i)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-5 col-sm-12">
                                                        <Input
                                                            name="coverage"
                                                            value={element.coverage}
                                                            onChange={(value: string) => changeCoverage('coverage', value, i)}
                                                            label="Cobertura"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <Button
                                                            icon="trash"
                                                            onClick={() => deleteCoverage(i)}
                                                            small
                                                            className="danger"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="comun-coverages" title="Coberturas comunes">
                                <div className="container p-2">
                                    {
                                        planPrevItems.map((element, i) => {
                                            return(
                                                <div
                                                    className="row"
                                                    key={element.name}
                                                >
                                                    <div className="col">
                                                        <p> {element.name} </p>
                                                    </div>
                                                    <div className="col">
                                                        <Input
                                                            name="value"
                                                            onChange={(value: string) => changeComunCoverage(i, value)}
                                                            value={element.value || ''}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="prices" title="Precios">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md col-sm-6 col-6">
                                            <Input
                                                name="travel_days"
                                                onChange={(value: string) => {
                                                    if(value){
                                                        setTravelDays(parseInt(value.toString()))
                                                        calcPriceDays(parseInt(value), dayPrice)
                                                    }
                                                }}
                                                value={travelDays}
                                                label="Dias de viaje"
                                                type="number"
                                                required
                                            />
                                        </div>
                                        <div className="col-md col-sm-6 col-6">
                                            <Input
                                                name="dayPrice"
                                                type="number"
                                                onChange={(value: string) => {
                                                    if(value){
                                                        setDayPrice(parseFloat(value.toString()))
                                                        calcPriceDays(travelDays, parseFloat(value))
                                                    }
                                                }}
                                                value={dayPrice}
                                                label="Precio por dia"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            prices.map((element, i) =>{
                                                return(
                                                    <div
                                                        className="col-md-2 col-sm-6 col-4"
                                                        key={i}
                                                    >
                                                        <Input
                                                            label={"Dia "+(i+1)}
                                                            value={element}
                                                            onChange={(value: string) => changeDayPrice(parseFloat(value), i)}
                                                            name="day"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="w-100 text-center">
                    <Button
                        className="success"
                        label="Guardar"
                        type="submit"
                    />
                </div>
            </form>
        </div>
    )
}

export default CreateEditPlans