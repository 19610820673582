import React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import {Image, Icon} from 'components'
import {WHATSAPP_LINK} from 'utils'
import {useDispatch} from 'react-redux'
const Header = () => {
    const dispatch = useDispatch()
    const logout = () => {
        dispatch({
            type: 'SET_USER',
            payload: null
        })
        navigate('/login')
    }
    return (
        <React.Fragment>
            <header className="header">
                <div className="p-1">
                    <div className="row">
                        <div className="col-8 text-left">
                            <div className="logo-container">
                                <Link
                                to="/"
                                >
                                <Image
                                    uri="logo.png"
                                />
                                </Link>
                            </div>
                        </div>
                        <div className="col-4 right-container text-right">
                            <div className="row w-100 justify-content-end">
                                <div className="col-2 p-0">
                                    <div className="w-100 text-right">
                                        <div className="whatsapp-container">
                                            <a
                                                href={WHATSAPP_LINK}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Icon
                                                name="whatsapp text-gray"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 p-0 menu-container text-left">
                                    <Icon
                                        name="sign-out cursor-pointer text-pink navbar-toggler p-2"
                                        onClick={() => logout()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
