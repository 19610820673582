import { Plan, Coverage} from 'models'
import React from 'react'
import {Button} from 'components'
import {formatMiles} from 'utils'

type Props = {
    plans: Plan[],
    plansToCompare: number[],
    useThisPlan: (element: Plan) => void,
    checkAndSetPlansToCompare: (element: Plan, isAdded: boolean) => void,
    viewPlanDetail: (element: Plan) => void,
}
export default (props: Props) => {
    return(
        <div className="plans">
            {
                props.plans?.map((element: Plan, i: number) => {
                    const isPlanAddedToCompare = props.plansToCompare.some((_element: number) => _element === element.id)
                    const splittedItems = element.items?.slice(0, 3) || []
                    return(
                        <div
                            className={"col-md-12 col-sm-12 plan"}
                            key={i}
                        >
                            {/* {
                                element.includesCovid && (
                                    <div className="ribbon ribbon-top-left">
                                        <span>Cubre Covid</span>
                                    </div>
                                )
                            } */}
                            <div className="row">
                                <div className={"col-md-4 text-center price-container "}>
                                    <h3 className="text-purple m-0">
                                        {element.price}
                                    </h3>
                                    <span className="text-dark-gray text-center subtitle">
                                        Precio aprox. por persona
                                    </span>
                                    <div className="container w-100 p-1">
                                        <Button
                                            onClick={() => props.useThisPlan(element)}
                                            label="Enviar venta"
                                            className={"success text-center text-white btn-bordered "}
                                        />
                                    </div>
                                    <div className={"row tabs-container "}>
                                        <div className="col col-md-6 w-100 col-sm-6 p-0">
                                            <Button
                                                label={isPlanAddedToCompare ? 'Agregada' : "Selec."}
                                                className={(isPlanAddedToCompare ? 'pink': 'purple')+" without-right-border-radius"}
                                                onClick={() => props.checkAndSetPlansToCompare(element, isPlanAddedToCompare)}
                                                disabled={!isPlanAddedToCompare && props.plansToCompare.length === 3}
                                            />
                                        </div>
                                        <div className="col col-md-6 w-100 col-sm-6 p-0">
                                            <Button
                                                label="Detalle"
                                                onClick={() => props.viewPlanDetail(element)}
                                                className="gray without-left-border-radius"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 logo-container">
                                    <img
                                        src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+element.insurance?.logo}
                                        alt={element.insurance?.name}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <h3 className="text-dark font-weight-bold text-center text-uppercase m-0">
                                        { element.name }
                                    </h3>
                                    <p className="text-dark text-center age-limite-subtitle">
                                        (Límite de edad: {element.age_limit} años)
                                    </p>
                                    <React.Fragment>
                                        {
                                            splittedItems.map((_element: Coverage, i: number) => {
                                                return(
                                                    <div
                                                        key={i}
                                                        className="row coverage-container"
                                                    >
                                                        <div
                                                            className="col-md-8 col-sm-8 ellipsis"
                                                        >
                                                            <p
                                                                className="text-left text-dark-gray m-0 lead"
                                                            >
                                                                {_element.name}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-4 col-sm-4 ellipsis"
                                                        >
                                                            <p
                                                                className="text-center text-dark-gray m-0 lead"
                                                            >
                                                                {formatMiles(parseFloat(_element.coverage), false)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}