import React from 'react'
import { Plan } from 'models'
import {Button} from 'components'
import {formatMiles} from 'utils'

type Props = {
    plansToCompare: number[],
    plans: Plan[],
    useThisPlan: (element: CustomPlan[]) => void
}
type CustomPlan = Plan & {isSelected?: boolean}
export default ({plansToCompare, plans, useThisPlan}: Props) => {
    const [coverages, setCoverages] = React.useState<Array<string[]>>([])
    const [plansToUse, setPlansToUse] = React.useState<(CustomPlan | undefined)[]>([])
    React.useEffect(() => {
        if(plansToCompare?.length){
            const _plans = plansToCompare.map(id => {
                let plan:  CustomPlan | undefined = plans.find((element: Plan) => element.id === id)
                if(plan){
                    plan.isSelected = true
                }
                return plan
            })
            let _coverages: Array<string[]> = []
            _coverages[0] = _plans?.[0]?.items?.map(element => element.name) || []
            _plans.forEach(element => {
                const _nCovs = element?.items?.map((element): string => element.coverage) || []
                _coverages.push(_nCovs)
            })
            setPlansToUse(_plans)
            setCoverages(_coverages)
        }
    }, [plansToCompare])
    const sendPlans = () => {
        let _plans: CustomPlan[] = []
        plansToUse.forEach((element: CustomPlan | undefined) => {
            if(element)
                _plans.push(element)
        })
        if(_plans)
            useThisPlan(_plans)
    }
    const removeFromSelected = (i: number) => {
        let _plans = [... plansToUse]
        if(_plans?.[i]){
            //@ts-ignore
            _plans[i].isSelected = !_plans[i].isSelected
            setPlansToUse(_plans)
        }
    }
    return(
        <div className="compare-plans">
            <div className="w-100 text-center bg-purple">
                <p className="m-0 p-1 text-white">
                    Aqui podras comparar y enviar la cotizacion al cliente segun el plan que escogiste.
                </p>
            </div>
            <div className="row header-insurances">
                <div className="col-md col-sm-12 insurance-top-card first-item"></div>
                <React.Fragment>
                    {
                        plansToUse.map((plan: CustomPlan | undefined, i: number) => {
                            return(
                                <div
                                    className={"col-md col-sm-12 insurance-top-card " +(i === (plansToCompare.length -1) && 'last-item')}
                                    key={i}
                                >
                                    <img
                                        src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+plan?.insurance?.logo}
                                        alt={plan?.insurance?.name}
                                    />
                                    {
                                        plan?.includesCovid && (
                                            <div
                                                className="rounded-pill bg-purple text-center w-100"
                                            >
                                                <p className="text-white text-uppercase m-0 p-0">
                                                    cubre covid
                                                </p>
                                            </div>
                                        )
                                    }
                                    <div className="w-100 text-center">
                                        <h3 className="text-purple m-0 p-1">
                                            {plan?.price}
                                        </h3>
                                        <Button
                                            className={plan?.isSelected ? 'danger' : "success "}
                                            label={plan?.isSelected ? 'Deseleccionar' : "Seleccionar"}
                                            onClick={() => removeFromSelected(i) }
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </React.Fragment>
            </div>
            <div className="row">
                {
                    coverages?.map((element: string[], i: number) => {
                        return(
                            <div
                                className={"col w-100 coverage-container text-center "+(i === 0 && 'is-first-child')}
                                key={i}
                            >
                                {
                                    element.map((coverage: string, _i: number) => {
                                        return(
                                            <div
                                                key={_i}
                                                className={"coverage-item"}
                                            >
                                                <p
                                                    className={"text-dark m-0 p-2 ellipsis w-100 "}
                                                >
                                                    {
                                                        parseFloat(coverage) ? formatMiles(parseFloat(coverage), false) : coverage
                                                    }
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="container text-center w-100">
                <Button
                    onClick={() => sendPlans()}
                    className="primary "
                    label="Usar estos planes"
                />
            </div>
        </div>
    )
}