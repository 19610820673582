import React from 'react'
import {Provider} from 'models'
import {Table} from 'components'

type Props = {
    element: Provider | null,
    onClose: () => void
}
const ViewProviders = (props: Props) => {
    const items: Array<{
        label: string,
        value: keyof Provider
    }> = [
        {
            label: '#',
            value: 'id'
        },
        {
            label: 'Nombre',
            value: 'name'
        },
        {
            label: 'Email',
            value: 'email'
        },
        {
            label: 'Descripcion',
            value: 'description'
        },
    ]
    return(
        <div className="w-100">
            <Table
                header={['Dato', 'Valor']}
                data={items.length}
                
            >
                {
                    items.map((element, i) => {
                        return(
                            <tr key={i}>
                                <th scope="row">
                                    {element.label || ''}
                                </th>
                                <td>
                                    {props.element?.[element.value] || ''}
                                </td>
                            </tr>
                        )
                    })
                }
            </Table>
        </div>
    )
}

export default ViewProviders